<template>
  <div>
    <ProductionReturn return-type="BC" />
  </div>
</template>
<script>

import ProductionReturn from './ProductionReturn'

export default {
  name: 'PostReturn',
  components: {
    ProductionReturn
  },
  data: function () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
